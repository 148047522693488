<script>
export default {
    name: "Icon",
    
    props: {
        icon: {
            type: String,
            default: ""
        }
    }
};
</script>

<template>
    <i :class="['fa-solid', icon]"></i>
</template>
