import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "@/router";
import setupAxiosInterceptors from "@/plugins/setupAxiosInterceptors";
import i18n from "@/plugins/i18n";
import assets from "@/mixins/assets";
import "@/plugins/bootstrap";
import VueScrollTo from "vue-scrollto";

import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";

import CustomComponents from "./components/custom";

setupAxiosInterceptors(store);

createApp({
    extends: App,
})
    .use(store)
    .use(router)
    .use(i18n)
    .use(CustomComponents)
    .use(VueScrollTo, {
        container: "body",
        duration: 500,
        easing: "ease",
        offset: 0,
        force: true,
        cancelable: true,
        onStart: false,
        onDone: false,
        onCancel: false,
        x: false,
        y: true
    })
    .mixin(assets)
    .mount("#app");
