function page(path) {
    return () => import(/* webpackChunkName: '' */ `../views/${path}.vue`).then(m => m.default || m);
}

export default [
    {
        path: "/",
        name: "Home",
        component: page("Home")
    },
    {
        path: "/cv",
        name: "CV",
        component: page("CV")
    },
    {
        path: "/cv-download",
        name: "CVDownload",
        component: page("CVDownload")
    },
    {
        path: "/:catchAll(.*)",
        redirect: { name: "Home" }
    }
];