export const state = {
    visible: true
};
export const getters = {
    getVisible: state => state.visible
};
export const mutations = {
    setVisible(state, payload) {
        state.visible = payload;
    }
};
