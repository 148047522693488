import axios from "axios";
import join from "url-join";

const setup = (store) => {
    const isAbsoluteURLRegex = /^(?:\w+:)\/\//;

    axios.interceptors.request.use((config) => {
        if (!isAbsoluteURLRegex.test(config.url)) {
            config.url = join(process.env.VUE_APP_API_BASE_URL, config.url);
        }

        config.headers.common["Access-Control-Allow-Origin"] = "*";
        config.headers.common["X-Language"] = store.getters["lang/getLang"];

        return config;
    }, (e) => {
        return Promise.reject(e);
    });
};

export default setup;