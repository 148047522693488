<script>
export default {
    name: "Timeline",
    
    props: {
        timeline: {
            type: Array,
            default: () => []
        }
    },
};
</script>

<template>
    <div class="timeline">
        <div
            v-for="(item, index) in timeline"
            :key="`timeline_${index}`"
            class="timeline-item"
        >
            <div class="timeline-item__body">
                <p
                    v-text="item.workplace"
                    class="timeline-item__body-title"
                />
                <p
                    v-text="item.company"
                    class="timeline-item__body-subtitle"
                />
                <p
                    v-if="item.work_time"
                    class="timeline-item__body-time"
                >
                    <Icon :icon="'fa-calendar-days me-2'" />
                    {{ item.work_time }}
                </p>
            </div>
            <div class="timeline-item__dot">
                <span class="timeline-item__dot-inner"></span>
            </div>
        </div>
    </div>
</template>