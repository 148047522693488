<script>
import {
    mapGetters,
    mapMutations
} from "vuex";
import Preload from "@/components/Preload";

export default {
    name: "App",
    
    components: {
        Preload
    },
    
    computed: {
        isCv() {
            return this.$route.name === "CV";
        },
    
        isCVDownload() {
            return this.$route.name === "CVDownload";
        },
        
        ...mapGetters({
            mode: "theme/getMode",
            visible: "preload/getVisible"
        })
    },
    
    watch: {
        visible(nv) {
            nv ? document.body.classList.add("is-preload") : document.body.classList.remove("is-preload");
        }
    },

    created() {
        document.title = this.$t("app.name");
    },

    mounted() {
        this.visible ? document.body.classList.add("is-preload") : document.body.classList.remove("is-preload");
        this.preloadDelay();
    },
    
    methods: {
        preloadDelay() {
            setTimeout(() => {
                this.setVisible(false);
            }, 1500);
        },
        
        ...mapMutations({
            setVisible: "preload/setVisible"
        })
    }
};
</script>

<template>
    <div
        id="main"
        :class="[mode, { 'cv-theme' : isCv }, { 'cv-theme-download' : isCVDownload }]"
        :key="`locale_${$root.$i18n.locale}-theme_${this.mode}`"
    >
        <transition
            name="fade"
            mode="out-in"
        >
            <Preload v-if="visible" />
        </transition>
        <router-view />
    </div>
</template>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease-out;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>