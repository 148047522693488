<script>
export default {
    name: "Title",
    
    props: {
        title: {
            type: String,
            default: ""
        },
    }
};
</script>

<template>
    <div class="title">
        <h2
            v-if="title"
            v-text="title"
        />
    </div>
</template>
