import assets from "@/mixins/assets";

export const state = {
    skills: [
        {
            name: "Vue",
            img: assets.methods.assets("/images/stack/vuejs.svg"),
            rating: 3.5,
        },
        {
            name: "JavaScript",
            img: assets.methods.assets("/images/stack/javascript.svg"),
            rating: 4,
        },
        {
            name: "Node",
            img: assets.methods.assets("/images/stack/nodejs.svg"),
            rating: 2,
        },
        {
            name: "npm",
            img: assets.methods.assets("/images/stack/npm.svg"),
            rating: 2,
        },
        {
            name: "HTML",
            img: assets.methods.assets("/images/stack/html.svg"),
            rating: 5,
        },
        {
            name: "CSS",
            img: assets.methods.assets("/images/stack/css.svg"),
            rating: 5,
        },
        {
            name: "SASS",
            img: assets.methods.assets("/images/stack/sass.svg"),
            rating: 4,
        },
        {
            name: "Vuetify",
            img: assets.methods.assets("/images/stack/vuetify.svg"),
            rating: 3.5,
        },
        {
            name: "Bootstrap",
            img: assets.methods.assets("/images/stack/bootstrap.svg"),
            rating: 4.5,
        },
        {
            name: "Git",
            img: assets.methods.assets("/images/stack/git.svg"),
            rating: 3,
        },
        {
            name: "Laravel",
            img: assets.methods.assets("/images/stack/laravel.png"),
            rating: 1,
        },
        {
            name: "MySql",
            img: assets.methods.assets("/images/stack/mysql.svg"),
            rating: 2,
        }
    ]
};

export const getters = {
    getSkills: state => state.skills
};

