import VueCookies from "vue-cookies";

export const state = {
    lang: VueCookies.get("lang") || process.env.VUE_APP_I18N_LANG,
    languages: []
};
export const getters = {
    getLang: state => state.lang,
    getLanguages: state => {
        state.languages = [];
        const lang = require.context("../../lang", true, /[A-Za-z0-9-_,\s]+\.json$/i);
        lang.keys().forEach(key => {
            const matched = key.match(/([A-Za-z0-9-_]+)\./i);
            if (matched && matched.length > 1) {
                const locale = matched[1];
                state.languages.push(locale);
            }
        });
        return state.languages;
    }
};
export const mutations = {
    setLang(state, payload) {
        VueCookies.set("lang", payload, "365d");
        state.lang = payload;
    }
};
