<script>
import { mapGetters } from "vuex";

export default {
    name: "Preload",
    
    computed: {
        ...mapGetters({
            mode: "theme/getMode"
        })
    }
};
</script>

<template>
    <div class="preload">
        <img
            :src="assets(`/images/logo/logo-${this.mode}-inscription.png`)"
            :alt="mode.split('-')[0]"
        >
        
        <div class="stage">
            <div class="dot-pulse"></div>
        </div>
    </div>
</template>
