import VueCookies from "vue-cookies";

export const state = {
    mode: VueCookies.get("mode") || "dark-theme"
};
export const getters = {
    getMode: state => state.mode
};
export const mutations = {
    setMode(state, payload) {
        VueCookies.set("mode", payload, "365d");
        state.mode = payload;
    }
};
